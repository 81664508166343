var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "host-bottom-toolbar" },
    [
      _c("BottomBarRight"),
      _c(
        "div",
        { staticClass: "relative rtb-flex" },
        [
          _c(
            "RtbGenericTip",
            {
              attrs: {
                placement: "top",
                path: "fluid",
                active:
                  _vm.getGameFtuxStep === 3 &&
                  !_vm.isMeeting &&
                  _vm.isModerator,
                offset: { distance: 100, shift: -60 },
                arrow: "",
                "tip-animation": "fade-up",
                "arrow-offset-end": { x: "50%", y: -8 },
                "arrow-offset-start": { x: "50%", y: "85%" },
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "p",
                        {
                          staticClass: "my-0 text-center",
                          staticStyle: { width: "250px" },
                        },
                        [_vm._v(" Check here for tips about what to do next ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.notes
                ? _c("Notes", { attrs: { success: _vm.allTeamsSubmitted } })
                : _c("HelpMessage", {
                    attrs: {
                      type: _vm.isHelpMessageVisible
                        ? _vm.messageType
                        : undefined,
                      message: _vm.isHelpMessageVisible
                        ? _vm.messageText
                        : undefined,
                    },
                  }),
            ],
            1
          ),
          _c("div", { staticClass: "host-bottom-toolbar__counter" }, [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.nOfSubmittedTeams) +
                  "/" +
                  _vm._s(_vm.nOfTeams) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("BottomBarLeft"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }