var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "help-message-transition", mode: "out-in" } },
    [
      _c("div", {
        key: _vm.type + "-" + _vm.message,
        staticClass: "help-message",
        class:
          ((_obj = {}), (_obj["help-message--" + _vm.type] = _vm.type), _obj),
        style: { fontSize: _vm.fontSize },
        domProps: { innerHTML: _vm._s(_vm.message) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }