import { HelpMessageTypes } from "./HelpMessageTypes"
import MediaType from "@shared/enums/Media"
import MissionType from "@shared/enums/Mission"
import Mode from "@shared/enums/Mode"

// TODO
// move to dictionary.js

export const SpacebarMessages = {
  // ==============================
  // Default messages for each mode
  // ==============================

  default: {
    text: "Hit Spacebar to move forward!",
    type: ""
  },

  playAdded: {
    text: "Watch the timer and wait for everyone to answer. <br/> Hit Space Bar when you are good and ready",
    type: HelpMessageTypes.URGENT
  },

  [Mode.Welcome]: {
    text: `Hit Space Bar to continue.
    <br>Audio is on for everyone`,
    type: HelpMessageTypes.URGENT
  },

  [Mode.Explain]: {
    text: `Read the challenge and then hit Space Bar.
    <br> Everyone is muted`,
    type: HelpMessageTypes.URGENT
  },

  [Mode.Play]: {
    text: `Time for the Scribes to submit an answer
    <br>You can hear scribes only`,
    type: HelpMessageTypes.IMPORTANT
  },

  [Mode.Huddle]: {
    text: `You can speak with one team at a time.
    <br> User '[' and ']' to change teams'`,
    type: HelpMessageTypes.IMPORTANT
  },

  [Mode.Social]: {
    text: `Award points by clicking on the stars under the players`,
    type: HelpMessageTypes.IMPORTANT
  },

  [Mode.Voting]: {
    text: `Cast your vote using the stars below player.
      <br>Hit space bar when satisfied`,
    type: HelpMessageTypes.IMPORTANT
  },

  [Mode.Results]: {
    text: `Here's where you can smack talk cause audio is on!
    <br> Hit Space Bar to continue`,
    type: HelpMessageTypes.STANDARD
  },

  // =============================================
  // Specific messages for each mission (behavior)
  // =============================================

  [MissionType.Text]: {
    [Mode.Explain]: {
      text: `Press the spacebar so teams can discuss and answer`,
      type: HelpMessageTypes.URGENT
    },

    [Mode.Play]: {
      text: `Once Teams have answered, press Spacebar`,
      type: HelpMessageTypes.QUIET
    }
  },

  [MissionType.MultipleChoice]: {
    [Mode.Explain]: {
      text: `Press the spacebar to reveal options.`,
      type: HelpMessageTypes.URGENT
    },

    [Mode.Play]: {
      text: `Once Teams have answered, press Spacebar`,
      type: HelpMessageTypes.QUIET
    }
  },

  [MissionType.BuzzIn]: {
    [Mode.Social]: {
      text: `Press spacebar to reveal puzzle. If question is music or video, click to play. Click on the Stars to give points. Reset Mission to clear buzzer`,
      type: HelpMessageTypes.IMPORTANT,

      [MediaType.Photo]: {
        text: `Players hit the BUZZER. Answer is on the card.
          <br> Award points with the stars below player`,
        type: HelpMessageTypes.IMPORTANT
      },

      [MediaType.Audio]: {
        text: `Hit play on the audio card. Players Buzz In and then pause music
            <br> Award points with the stars below player`,
        type: HelpMessageTypes.IMPORTANT
      },

      [MediaType.Video]: {
        text: `Video plays automatically. Players Buzz In and then pause the video
            <br> Award points with the stars below player`,
        type: HelpMessageTypes.IMPORTANT
      }
    }
  },

  [MissionType.Charades]: {
    [Mode.Social]: {
      text: `Hit "Start" to reveal the clues to the participants`,
      type: HelpMessageTypes.IMPORTANT,

      started: {
        text: `Hit "Pause" to stop them from answering, or <br>
        hit "End" to stop the game and reveal the answers
        `,
        type: HelpMessageTypes.IMPORTANT
      }
    }
  },

  [MissionType.TelephonePictionary]: {
    [Mode.Play]: {
      lastStep: {
        text: `Let's see the results`,
        type: HelpMessageTypes.IMPORTANT
      }
    }
  },

  [MissionType.SpiralDrawing]: {
    [Mode.Play]: {
      text: `Use Spacebar to bring in one at a time. <br> Shift+Spacebar to move on.`,
      type: HelpMessageTypes.IMPORTANT
    }
  },

  [MissionType.Categories]: {
    [Mode.Explain]: {
      text: `Press the spacebar to start the game.`,
      type: HelpMessageTypes.URGENT
    },

    [Mode.Play]: {
      text: `Click "Reveal Answers" when time is up`,
      type: HelpMessageTypes.STANDARD
    }
  },

  [MissionType.Info]: {
    [Mode.Explain]: {
      text: `Hit Space Bar to Continue <br>`,
      type: HelpMessageTypes.URGENT
    },

    [Mode.Play]: {
      text: `Hit Space Bar to move on`,
      type: HelpMessageTypes.URGENT
    }
  },

  [MissionType.Giphy]: {
    [Mode.Explain]: {
      text: `Press the spacebar to continue.`,
      type: HelpMessageTypes.URGENT
    },

    [Mode.Play]: {
      text: `Teams enter a search term in the box and submit`,
      type: HelpMessageTypes.QUIET
    }
  },

  [MissionType.Poll]: {
    [Mode.Explain]: {
      text: `Press the spacebar to continue.`,
      type: HelpMessageTypes.URGENT
    },

    [Mode.Play]: {
      text: `Every player can vote (typically, though can be set to "per team"). Once everyone has voted, press Spacebar.`,
      type: HelpMessageTypes.STANDARD
    }
  },

  [MissionType.Lipdub]: {
    [Mode.Play]: {
      text: `Start the song with the Green Arrow. Then hit Space Bar to advance the next lyric`,
      type: HelpMessageTypes.IMPORTANT
    },

    [Mode.Social]: {
      text: `Click green triangle to play song. Press spacebar to advance the lyrics. Click stars to award points.`,
      type: HelpMessageTypes.STANDARD
    }
  },

  [MissionType.DrawingEachTeam]: {
    [Mode.Welcome]: {
      text: `Say: "You are going to work together as a team to draw a picture"`,
      type: HelpMessageTypes.STANDARD
    },

    [Mode.Play]: {
      text: `Say: Everyone can draw. When DONE, scribes title and submit`,
      type: HelpMessageTypes.STANDARD
    },

    [Mode.Voting]: {
      text: `Say: Players can give some appreciation to the other teams by clicking the stars`,
      type: HelpMessageTypes.QUIET
    }
  },

  [MissionType.DrawingPictionary]: {
    [Mode.Play]: {
      text: `Choose a team in lower left of canvas. Unlimited guesses, no penalty for incorrect answers.`,
      type: HelpMessageTypes.IMPORTANT,

      teamAssigned: {
        text: "Excellent! Everyone on the drawing team can draw. Scribes on the other teams guess. ",
        type: HelpMessageTypes.IMPORTANT
      }
    }
  },

  [MissionType.PlayerRating]: {
    [Mode.Explain]: {
      text: `Press Spacebar so teammates can hear each other.`,
      type: HelpMessageTypes.URGENT
    },

    [Mode.Huddle]: {
      text: `You can only be heard by one team at a time. Use brackets to rotate teams. End your sentence and pause before switching to another team.`,
      type: HelpMessageTypes.QUIET
    },

    [Mode.Social]: {
      text: `Double-click on center players to send them back to their team to discuss and nominate their scribe. When ready, bring the scribes to the center to make the pitch.  Click to stars to award points.`,
      type: HelpMessageTypes.STANDARD
    }
  },

  [MissionType.FactMatch]: {
    [Mode.Explain]: {
      text: `You are about to be heard by only one team at a time. Use complete sentence and pause before space bar.`,
      type: HelpMessageTypes.STANDARD
    },

    [Mode.Huddle]: {
      text: `Only one team can hear you. Use Bracket keys to rotate. Finish sentence and pause before pressing bracket or spacebar.`,
      type: HelpMessageTypes.STANDARD
    },

    [Mode.Results]: {
      text: `Everyone can hear you. Press Spacebar to show results. Correct answer is below player. Red means the team placed it incorrectly.`,
      type: HelpMessageTypes.STANDARD
    }
  },

  [MissionType.PhotoBooth]: {
    [Mode.Play]: {
      text: `Tip: Players drag props to face. Props can be turned and resized. When ready, they take the photo.`,
      type: HelpMessageTypes.QUIET
    },

    [Mode.Voting]: {
      text: `Players can give some appreciation to the other players by clicking the stars.`,
      type: HelpMessageTypes.QUIET
    }
  },

  [MissionType.MatchGame]: {
    [Mode.Explain]: {
      text: `Say: Scribes drag to swap the bottom images until they align with the matching top image`,
      type: HelpMessageTypes.STANDARD
    },

    [Mode.Social]: {
      text: `Teams can reveal the correct answer once they submit`,
      type: HelpMessageTypes.QUIET
    }
  },

  [MissionType.TwoTruths]: {
    [Mode.Explain]: {
      text: `Say: Scribe will enter 2 truthful statements and 1 lie about themself. <br> You will read your answers as if all are TRUE and everyone will vote on which is the lie. <br> You will get point for how many you can fool.`,
      type: HelpMessageTypes.STANDARD
    },

    [Mode.Play]: {
      text: `Say: Enter one truth about yourself, then submit. <br>
      Enter another true statement, and submit. <br>
      Then enter a lie about yourself and submit.`,
      type: HelpMessageTypes.STANDARD
    }
  },

  [MissionType.TwoTruthsReveal]: {
    [Mode.Explain]: {
      text: `Say: Scribes, I will call you on-stage to read your answers as if all are TRUE and everyone will vote on which is the lie. You will get point for how many you can fool. Press the spacebar`,
      type: HelpMessageTypes.STANDARD
    },

    [Mode.Social]: {
      text: `Have the scribe read their answers, teams vote. Press the spacebar to bring up the next player.`,
      type: HelpMessageTypes.IMPORTANT
    }
  },

  [MissionType.Award]: {
    [Mode.Explain]: {
      text: `Press the spacebar to continue.`,
      type: HelpMessageTypes.URGENT
    },

    [Mode.Social]: {
      text: `These are your Top Three (or less)....Who will win? Press Spacebar to reveal the winner. `,
      type: HelpMessageTypes.IMPORTANT
    }
  },

  [MissionType.RoyalRumble]: {
    [Mode.Explain]: {
      text: `Say: Scribes choose one picture from Row 1 and one from Row Two. As a team, discuss why your combo is THE BEST and decide you from your team will make the pitch.`,
      type: HelpMessageTypes.STANDARD
    },
    [Mode.Play]: {
      text: `Once Teams have selected 2 photos each, Say: I'm going to put you in groups to prepare your pitch.   Pause and then press the spacebar (at which point only one team can hear you at a time).`,
      type: HelpMessageTypes.QUIET
    },
    [Mode.Huddle]: {
      text: `You can only be heard by one team at a time. Use brackets to rotate teams. End your sentence and pause before switching to another team. Teams can pass the scribe to the presenter.`,
      type: HelpMessageTypes.STANDARD
    },
    [Mode.Social]: {
      text: `Presenter from each team is in the center to make the pitch. Award points by clicking the stars.`,
      type: HelpMessageTypes.STANDARD
    }
  },

  [`Game Over`]: {
    [Mode.Explain]: {
      text: `Press the spacebar to continue.`,
      type: HelpMessageTypes.URGENT
    },
    [Mode.Over]: {
      text: `Press Spacebar to reveal each team’s place, then switch to Meeting mode.`,
      type: HelpMessageTypes.STANDARD
    },
    [Mode.Meeting]: {
      text: `If Multiple rooms; Click Hourglass>End Game to send teams back to lobby. Then click lobby to return to lobby.`,
      type: HelpMessageTypes.STANDARD
    },
    [Mode.Voting]: {
      text: `Invite players to explore dropdown menu. Turn on All Talk. If more than 4 teams, make everyone a scribe or switch to meeting mode.`,
      type: HelpMessageTypes.STANDARD
    },
    [Mode.Results]: {
      text: `If more than 4 teams, switch to Meeting mode. If Multple rooms; Click Hourglass>End Game to send teams back to lobby. If multiple rounds, click lobby to return to lobby.`,
      type: HelpMessageTypes.STANDARD
    }
  },

  // =============================================
  //                  Custom cases
  // =============================================

  allTeamsSubmitted: {
    text: `All answers have been submitted, <br/> press spacebar to continue`,
    type: HelpMessageTypes.SUCCESS
  },
  playsAdded: {
    text: `Watch the timer and wait for everyone to answer. <br/> Hit Space Bar when you are good and ready`,
    type: HelpMessageTypes.URGENT
  },
  assignedDrawingTeam: {
    text: `Excellent! Everyone on the drawing team can draw. Scribes on the other teams guess.`,
    type: HelpMessageTypes.IMPORTANT
  }
}
