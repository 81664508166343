


















import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  nextTick
} from "@vue/composition-api"
import { ResizeObserver } from "vue-resize"
import vClickOutside from "v-click-outside"
import useStore from "@/use/useStore"

export default defineComponent({
  name: "Notes",
  components: { ResizeObserver },
  directives: {
    clickOutside: vClickOutside.directive
  },
  setup() {
    const opened = ref()
    const active = ref()
    const notesContainer = ref()
    const containerHeight = ref()
    const { store } = useStore()
    const notes = computed(() => store.getters.getCurrentMission?.notes)
    const isActive = computed(() => active.value || opened.value)

    const style = computed(() =>
      opened.value
        ? {
            height: `${containerHeight.value}px`
          }
        : {}
    )

    function updateHeight() {
      containerHeight.value = notesContainer.value.scrollHeight
      checkActive()
    }

    function close() {
      opened.value = false
    }

    function toggle() {
      if (isActive.value) {
        opened.value = !opened.value
      }
    }

    function checkActive() {
      if (notesContainer.value) {
        active.value =
          notesContainer.value.scrollHeight !==
          notesContainer.value.offsetHeight
      }
    }

    function update() {
      nextTick(() => {
        updateHeight()
        checkActive()
      })
    }

    onMounted(() => {
      update()
    })

    watch(notes, (prev, next) => {
      if (prev !== next) {
        update()
      }
    })

    return {
      isActive,
      close,
      toggle,
      opened,
      style,
      updateHeight,
      notes,
      notesContainer,
      containerHeight
    }
  }
})
