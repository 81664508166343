var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bottom-bar-right pl-2" },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "div",
                { staticStyle: { "font-size": "12px", color: "#bebebe" } },
                [_vm._v("Hosting")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    color: "#fff",
                    "font-size": "16px",
                    "font-weight": "bold",
                    margin: "0 10px",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(this.game.externalName || this.game.name) + " "
                  ),
                ]
              ),
            ]
          ),
          _c("v-spacer"),
        ],
        1
      ),
      _vm.isNoteBtnVisible
        ? _c(
            "RtbControlButton",
            {
              staticClass: "bottom-bar-right__note-btn mr-2",
              class: {
                "bottom-bar-right__note-btn--active": _vm.isNoteDialogVisible,
              },
              on: { click: _vm.toggleNoteDialog },
            },
            [_c("v-icon", [_vm._v("event_note")])],
            1
          )
        : _vm._e(),
      !_vm.isMeetingMode && _vm.isHostToolsVisible
        ? [
            _vm.isMultiTeam
              ? _c("settings-selector", {
                  staticClass: "bottom-bar-right__scribe-options--multiteam",
                  attrs: {
                    "default-title": "Select New Scribe(s)",
                    "default-icon": "stars",
                    items: _vm.multiTeamScribesItems,
                  },
                  model: {
                    value: _vm.playType,
                    callback: function ($$v) {
                      _vm.playType = $$v
                    },
                    expression: "playType",
                  },
                })
              : _c("settings-selector", {
                  staticClass: "bottom-bar-right__scribe-options",
                  attrs: {
                    "default-title": "Select New Scribe(s)",
                    "default-icon": "stars",
                    items: _vm.scribesItems,
                  },
                  model: {
                    value: _vm.playType,
                    callback: function ($$v) {
                      _vm.playType = $$v
                    },
                    expression: "playType",
                  },
                }),
            _c(
              "RtbControlButton",
              {
                staticClass: "ml-2",
                staticStyle: { width: "55px", height: "22px" },
                attrs: { sm: "" },
                on: { click: _vm.selectNextPlayers },
              },
              [
                _c("svg-icon", {
                  staticStyle: { width: "55px" },
                  attrs: { name: "scribe-next" },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }