<template>
  <div class="bottom-bar-left">
    <div class="bottom-bar-left__group">
      <RtbGenericTip
        placement="top"
        path="fluid"
        :active="getGameFtuxStep === 3 && !isMeeting && isModerator"
        :show-delay="500"
        :offset="{ distance: 130, shift: 0 }"
        arrow
        tip-animation="fade-up"
        :arrow-offset-end="{ x: '40%', y: -16 }"
        :arrow-offset-start="{ x: '50%', y: '90%' }"
      >
      </RtbGenericTip>
      <RtbGenericTip>
        <template #content>
          <p style="width: 250px" class="my-0 text-center">
            Switch between Meet and Play modes
          </p>
        </template>
        <MeetToggle class="bottom-bar-left__meet-toggle" />
      </RtbGenericTip>
      <RtbGenericTip
        placement="top"
        path="fluid"
        :active="getGameFtuxStep === 3 && !isMeeting && isModerator"
        :show-delay="800"
        :offset="{ distance: 220, shift: 40 }"
        arrow
        tip-animation="fade-up"
        :arrow-offset-end="{ x: '50%', y: -16 }"
        :arrow-offset-start="{ x: '50%', y: '90%' }"
      >
        <template #content>
          <p style="width: 250px" class="my-0 text-center">
            Breakout into smaller groups here
          </p>
        </template>
        <BreakoutControl />
      </RtbGenericTip>
    </div>
    <MainDrawerToggle />
  </div>
</template>

<script>
import Vue from "vue"

import { mapState, mapGetters } from "vuex"
import Mode from "@shared/enums/Mode"
import Role from "@shared/enums/Role"
import { RtbGenericTip } from "@/components/ui"

import BreakoutControl from "@/components/GroupTeams/Common/BreakoutControl.vue"

import MeetToggle from "@/components/GroupTeams/Common/MeetToggle"
import RTBControlButton from "@/components/ui/ControlButton/ControlButton"
import MainDrawerToggle from "@/components/drawers/main/MainDrawerToggle.vue"
export default Vue.extend({
  name: "BottomBarLeft",
  components: {
    RTBControlButton,
    MeetToggle,
    MainDrawerToggle,
    BreakoutControl,
    RtbGenericTip
  },
  computed: {
    ...mapState(["gameID", "orgID"]),
    ...mapGetters({ mode: "getCurrentMode" }),
    ...mapGetters(["getGameFtuxStep", "isHostToolsVisible"]),
    ...mapGetters("auth", ["isModerator", "isHost", "user"]),
    isMainPanelBtnVisible() {
      return this.user?.role === Role.HOST
    },
    isMeeting() {
      return this.mode === Mode.Meeting
    }
  }
})
</script>
<style lang="scss">
.bottom-bar-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding-left: 8px;
  padding-right: 4px;

  &__group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
  }
  &__menu-bth {
    width: 60px;
  }
  &__meet-toggle {
    z-index: 1;
    position: relative;
    width: 160px;
    height: 40px;
  }
}
</style>
