




































import { mapState, mapGetters } from "vuex"
import _, { get } from "lodash"

import { RtbGenericTip } from "@/components/ui"
import Mode from "@shared/enums/Mode"
import User from "@shared/User"
import MissionType from "@shared/enums/Mission"
import useTelephonePictionary from "@/components/GroupTeams/Common/Games/TelephonePictionary/useTelephonePictionary"

import BottomBarLeft from "./BottomBarLeft.vue"
import HelpMessage from "./HelpMessage.vue"
import Notes from "./Notes.vue"
import BottomBarRight from "./BottomBarRight.vue"
import { SpacebarMessages } from "./SpacebarMessages.consts"
import useDictionary from "@/use/useDictionary"

import Team from "@shared/Team"

export default {
  name: "HostBottomToolbar",
  components: {
    Notes,
    BottomBarLeft,
    RtbGenericTip,
    HelpMessage,
    BottomBarRight
  },
  setup() {
    const { fakePlays, isLastStep } = useTelephonePictionary()
    const { getMessageWithArguments, HelpMessage } = useDictionary()
    return {
      isLastStep,
      fakePlays,
      getMessageWithArguments,
      HelpMessage
    }
  },
  data() {
    return {
      teamAssigned: false,
      notesActive: false
    }
  },
  watch: {
    assignedTeamId(nextVal, prevVal) {
      if (prevVal && prevVal !== nextVal) {
        this.teamAssigned = true
      }
    }
  },
  computed: {
    ...mapGetters(["gameStatus", "getGameFtuxStep", "missionPlaysArray"]),
    ...mapGetters({
      mission: "getCurrentMission",
      mode: "getCurrentMode"
    }),
    ...mapState("drawing", ["assignedTeamId"]),
    ...mapGetters("auth", ["isModerator", "user"]),
    teams() {
      return Team.normalize(this.$store.getters.chats ?? {}).filter(
        team => !Team.isSpecialPurpose(team)
      )
    },
    nOfUsersWithoutTeams() {
      const isUserHostLike = this.$store.getters["group/isUserHostLike"]

      return this.$store.getters.onlineUsersArray.reduce((acc, val) => {
        if (
          !User.isSpecialPurposeUser(val) &&
          !User.isAuditorLike(val) &&
          !isUserHostLike(val) &&
          this.teams.every(team => team.id !== val.teamID)
        )
          return acc + 1
        return acc
      }, 0)
    },
    notes() {
      return this.mission?.notes
    },
    teamIds() {
      return Object.keys(this.$store.getters.chats)
    },
    scribes() {
      return this.$store.getters.onlineUsersArray.filter(
        user =>
          User.isScribe(user) &&
          User.isSelectable(user) &&
          this.teamIds.includes(user.teamID)
      )
    },
    missionPlays() {
      const behavior = this.$store.getters.getCurrentMission?.behavior
      if (behavior === MissionType.TelephonePictionary) {
        return this.fakePlays || []
      }

      return this.$store.getters.missionPlaysArray || []
    },
    allTeamsSubmitted() {
      if (!this.scribes.length) return false

      return this.scribes.every(scribe => {
        return this.missionPlays.some(play => play.userID === scribe.id)
      })
    },
    nOfSubmittedTeams() {
      return _(this.missionPlays).groupBy("teamID").map().value().length
    },
    nOfTeams() {
      return this.teams.length
    },
    nOfMissionPlays() {
      return this.missionPlays.length
    },

    isMeeting() {
      return this.mode === Mode.Meeting
    },
    isHelpMessageVisible() {
      return !this.user?.spacebarHintsDisabled || this.allTeamsSubmitted
    },
    helpMessage() {
      if (this.notes) {
        return { text: this.notes }
      }

      if (this.nOfUsersWithoutTeams > 0) {
        return {
          text: this.getMessageWithArguments(
            this.HelpMessage.YOU_HAVE_N_UNASSIGNED_USERS,
            this.nOfUsersWithoutTeams
          ),
          type: "urgent"
        }
      }

      const { media, mode } = this
      const behavior = this.$store.getters.getCurrentMission?.behavior
      const showMedia = this.gameStatus.media

      if (this.allTeamsSubmitted) {
        return get(SpacebarMessages, "allTeamsSubmitted")
      }

      if (
        this.missionPlaysArray.length > 0 &&
        [Mode.Play, Mode.Social, Mode.Huddle].includes(mode) &&
        !this.isLastStep
      ) {
        return get(SpacebarMessages, "playAdded")
      }

      const defaultModeMessage = get(
        SpacebarMessages,
        [mode],
        SpacebarMessages.default
      )

      const defaultBehaviorMessage = get(
        SpacebarMessages,
        [behavior, mode],
        defaultModeMessage
      )

      const resultedMessage = get(
        SpacebarMessages,
        [
          behavior,
          mode,
          (showMedia && media) ||
            (this.teamAssigned && "teamAssigned") ||
            (this.isLastStep && "lastStep")
        ],
        defaultBehaviorMessage
      )

      return resultedMessage
    },
    messageType() {
      return this.helpMessage?.type
    },
    messageText() {
      return this.helpMessage?.text
    }
  }
}
