var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bottom-bar-left" },
    [
      _c(
        "div",
        { staticClass: "bottom-bar-left__group" },
        [
          _c("RtbGenericTip", {
            attrs: {
              placement: "top",
              path: "fluid",
              active:
                _vm.getGameFtuxStep === 3 && !_vm.isMeeting && _vm.isModerator,
              "show-delay": 500,
              offset: { distance: 130, shift: 0 },
              arrow: "",
              "tip-animation": "fade-up",
              "arrow-offset-end": { x: "40%", y: -16 },
              "arrow-offset-start": { x: "50%", y: "90%" },
            },
          }),
          _c(
            "RtbGenericTip",
            {
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "p",
                        {
                          staticClass: "my-0 text-center",
                          staticStyle: { width: "250px" },
                        },
                        [_vm._v(" Switch between Meet and Play modes ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("MeetToggle", { staticClass: "bottom-bar-left__meet-toggle" })],
            1
          ),
          _c(
            "RtbGenericTip",
            {
              attrs: {
                placement: "top",
                path: "fluid",
                active:
                  _vm.getGameFtuxStep === 3 &&
                  !_vm.isMeeting &&
                  _vm.isModerator,
                "show-delay": 800,
                offset: { distance: 220, shift: 40 },
                arrow: "",
                "tip-animation": "fade-up",
                "arrow-offset-end": { x: "50%", y: -16 },
                "arrow-offset-start": { x: "50%", y: "90%" },
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "p",
                        {
                          staticClass: "my-0 text-center",
                          staticStyle: { width: "250px" },
                        },
                        [_vm._v(" Breakout into smaller groups here ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("BreakoutControl")],
            1
          ),
        ],
        1
      ),
      _c("MainDrawerToggle"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }