import { HelpMessage, Misc } from "@/dictionary"

function getMessageWithArguments(
  message: HelpMessage | Misc,
  ...args: string[]
) {
  return args.reduce(
    (acc, val, index) => acc.replace(`$${index + 1}`, val),
    message
  )
}

export default function useDictionary() {
  return {
    getMessageWithArguments,
    HelpMessage,
    Misc
  }
}
