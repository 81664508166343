var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "notes",
      on: { click: _vm.toggle },
    },
    [
      _c(
        "div",
        {
          ref: "notesContainer",
          staticClass: "notes__cnt",
          class: {
            "notes__cnt--opened": _vm.opened,
            "notes__cnt--active": _vm.isActive,
            "notes__cnt--success": _vm.$attrs.success,
          },
          style: _vm.style,
        },
        [
          _vm._v(" " + _vm._s(_vm.notes) + " "),
          _c("ResizeObserver", { on: { notify: _vm.updateHeight } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }