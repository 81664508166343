<template>
  <transition name="help-message-transition" mode="out-in">
    <div
      class="help-message"
      :class="{ [`help-message--${type}`]: type }"
      :key="`${type}-${message}`"
      :style="{ fontSize: fontSize }"
      v-html="message"
    />
  </transition>
</template>

<script>
import Vue from "vue"
export default Vue.extend({
  name: "HelpMessage",
  props: {
    type: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      default: undefined
    }
  },
  computed: {
    fontSize() {
      const length = this.message?.length
      return length > 120 ? `calc(18px - ${length % 60}%)` : "18px"
    }
  }
})
</script>
<style lang="scss">
.help-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 550px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  box-sizing: border-box;
  border-radius: 4px;

  &--important {
    border: 1px solid currentColor;
    color: #8680ff;
  }
  &--urgent {
    background: #8680ff;
    box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.25),
      inset 0px 1px 0px rgba(255, 255, 255, 0.3);
  }
  &--success {
    background: $color-correct;
  }
  &--quiet {
    font-weight: 400;
    color: #c4c4c4;
    background: #1e2025;
  }
  &-transition-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all cubic-bezier(0.45, 0.3, 0.17, 1.4) 0.6s;
  }
  &-transition-leave-active {
    opacity: 1;
    transform: scale(1);
    transition: all ease 0.6s;
  }
  &-transition-enter {
    opacity: 0;
    transform: scale(0.8);
  }
  &-transition-leave-to {
    opacity: 0;
    transform: scale(1);
  }
}
</style>
