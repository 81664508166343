<template>
  <div class="bottom-bar-right pl-2">
    <div>
      <div style="display: flex; align-items: center">
        <div style="font-size: 12px; color: #bebebe">Hosting</div>
        <div
          style="
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            margin: 0 10px;
          "
        >
          {{ this.game.externalName || this.game.name }}
        </div>
      </div>
      <v-spacer />
    </div>
    <RtbControlButton
      v-if="isNoteBtnVisible"
      class="bottom-bar-right__note-btn mr-2"
      @click="toggleNoteDialog"
      :class="{ 'bottom-bar-right__note-btn--active': isNoteDialogVisible }"
    >
      <v-icon>event_note</v-icon>
    </RtbControlButton>
    <template v-if="!isMeetingMode && isHostToolsVisible">
      <settings-selector
        v-if="isMultiTeam"
        class="bottom-bar-right__scribe-options--multiteam"
        default-title="Select New Scribe(s)"
        default-icon="stars"
        v-model="playType"
        :items="multiTeamScribesItems"
      />
      <settings-selector
        v-else
        class="bottom-bar-right__scribe-options"
        default-title="Select New Scribe(s)"
        default-icon="stars"
        v-model="playType"
        :items="scribesItems"
      />
      <RtbControlButton
        sm
        class="ml-2"
        style="width: 55px; height: 22px"
        @click="selectNextPlayers"
      >
        <svg-icon style="width: 55px" name="scribe-next" />
      </RtbControlButton>
    </template>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapState } from "vuex"
import SettingsSelector from "@/components/SettingsSelector"
import { db } from "@/firebase"
import { PlayType as MissionPlayType, SelectType } from "@shared/enums"
import NavigationService from "@/services/navigation.service"
import Mode from "@shared/enums/Mode"
import MissionType from "@shared/enums/Mission"
import { RtbControlButton } from "@/components/ui"
import { HOST } from "@shared/enums/Role"

const PlayType = {
  ONE: "one",
  TEAM: "team",
  ALL: "all",
  ALL_SCRIBE: "all-scribe",
  NONE: "none"
}

export default Vue.extend({
  name: "BottomBarRight",
  components: {
    RtbControlButton,
    SettingsSelector
  },
  data() {
    return {
      isHoverEnabled: true,
      multiTeamScribesItems: [
        {
          name: "Just one scribe",
          svgIcon: "single-user",
          value: "one"
        },
        {
          name: "One scribe per team",
          svgIcon: "team-users",
          value: "team"
        },
        {
          name: "Two scribes per team",
          svgIcon: "two-scribes",
          value: "all"
        },
        {
          name: "Everyone is scribe",
          svgIcon: "all-users",
          value: "all-scribe"
        }
      ],
      scribesItems: [
        {
          name: "Just one scribe",
          svgIcon: "single-user",
          value: "one"
        },
        {
          name: "One scribe per team",
          svgIcon: "team-users",
          value: "team"
        },
        {
          name: "Everyone is scribe",
          svgIcon: "all-users",
          value: "all"
        },
        {
          name: "No Scribes",
          svgIcon: "times-regular",
          value: "none"
        }
      ]
    }
  },
  computed: {
    ...mapState(["isNoteDialogVisible"]),
    ...mapGetters("auth", ["isModerator", "user"]),
    ...mapGetters([
      "audioDrawer",
      "game",
      "settingsBar",
      "auth/isHost",
      "gameStatus",
      "gameID",
      "orgID",
      "isHostToolsVisible"
    ]),
    ...mapGetters("group", ["isMultiTeam"]),
    ...mapGetters("GameUsers", ["usersArray"]),
    ...mapGetters({
      currentMode: "getCurrentMode",
      missionID: "currentMission"
    }),
    isNoteBtnVisible() {
      return this.user.role === HOST
    },
    mission() {
      return this.gameStatus?.current_mission
    },
    scribesItemsOptions() {
      if (this.isMultiTeam) {
        return this.multiScribesItems
      } else {
        return this.scribesItems
      }
    },
    isMeetingMode() {
      return this.currentMode === "meeting"
    },
    playTypeRef() {
      return db
        .auxiliary()
        .ref(
          `org/${this.orgID}/game/${this.gameID}/gameStatus/current_mission/playType`
        )
    },
    lyrics() {
      return String(this.mission?.instructions).split(";")
    },
    nOfLyrics() {
      return this.lyrics?.length
    },
    playType: {
      get() {
        const type = String(this.mission?.playType)
        if (type.includes("Individual:")) {
          return PlayType.ALL
        } else if (type === MissionPlayType.ONE_AT_A_TIME) {
          return PlayType.ONE
        } else if (type === MissionPlayType.NO_SCRIBES) {
          return PlayType.NONE
        } else {
          return PlayType.TEAM
        }
      },
      async set(value) {
        if (value === PlayType.TEAM) {
          await this.playTypeRef.set(MissionPlayType.TEAM_SPEED_DOES_NOT_MATTER)
          await this.onSelectOneScribePerTeam(true)
        } else if (value === PlayType.ALL) {
          await this.playTypeRef.set(
            MissionPlayType.INDIVIDUAL_SPEED_DOES_NOT_MATTER
          )
          await this.onSelectSelectAll()
        } else if (value === PlayType.ONE) {
          await this.playTypeRef.set(MissionPlayType.ONE_AT_A_TIME)
          await this.onSelectOneScribe(true)
        } else if (value === PlayType.NONE) {
          await this.playTypeRef.set(MissionPlayType.NO_SCRIBES)
          this.onSelectSelectNone()
        } else if (value === PlayType.ALL_SCRIBE) {
          this.onAllScribe()
        }
      }
    }
  },
  methods: {
    toggleNoteDialog() {
      this.$store.commit(
        "SET_IS_NOTE_DIALOG_VISIBLE",
        !this.isNoteDialogVisible
      )
    },
    selectNextPlayers() {
      NavigationService.select(
        this.orgID,
        this.gameID,
        "select-one-from-each-team"
      )
    },
    openAudioOnHover() {
      if (this.isHoverEnabled) {
        this.toggleAudioDrawer(true)
      }
    },
    // TODO
    // implement in API
    onAllScribe() {
      const users = this.usersArray
      const update = {}
      for (const user of users) update[`${user.id}/selected`] = true
      db.auxiliary().ref(`org/1/users`).update(update)
    },
    async onSelectOneScribe(freez) {
      await NavigationService.select(
        this.orgID,
        this.gameID,
        SelectType.SELECT_ONE
      )
      if (this.isOneAtATime && !freez) await this.next(true)
    },
    async onSelectSelectAll() {
      await NavigationService.select(
        this.orgID,
        this.gameID,
        SelectType.SELECT_ALL
      )
    },
    async onSelectSelectNone() {
      await NavigationService.select(this.orgID, this.gameID, SelectType.NONE)
    },
    async onSelectOneScribePerTeam(freez) {
      await NavigationService.select(
        this.orgID,
        this.gameID,
        SelectType.SELECT_ONE_FROM_EACH_TEAM
      )
      if (this.isOneAtATime && !freez) this.next(true)
    },
    async nextScribe() {
      const currentPlayTypeIdx = this.scribesItems.findIndex(
        scribeItem => scribeItem.value === this.playType
      )
      if (currentPlayTypeIdx < this.scribesItems.length - 1) {
        this.playType = this.scribesItems[currentPlayTypeIdx + 1].value
      }
    },
    async next(noScribe) {
      if (this.missionIDOverride) this.missionIDOverride = null
      const { mission, orgID, gameID, currentMode: mode, nOfLyrics } = this
      if (mode === Mode.Social && mission?.behavior === MissionType.Lipdub) {
        try {
          const { id } = mission
          const { committed } = await db
            .auxiliary()
            .ref(`org/${orgID}/game/${gameID}/lipdub/${id}/index`)
            .transaction(index => {
              if (isNaN(index)) return 0
              const newIndex = index + 1
              if (newIndex >= nOfLyrics) return
              return newIndex
            })
          if (committed) {
            if (!noScribe) {
              await NavigationService.select(orgID, gameID, "select-one")
            }
            return
          }
        } catch (e) {
          console.error(e)
        }
      }
      this.working = true
      try {
        await NavigationService.next(orgID, gameID, this.game?.experimental)
      } catch (e) {
        console.error(e)
      }
      this.working = false
    },
    toggleAudioDrawer(value = !this.audioDrawer) {
      this.isHoverEnabled = false
      this.$store.dispatch("setAudioDrawer", value)
      this.$store.dispatch("setSettingsBar", false)
      setTimeout(() => {
        this.isHoverEnabled = true
      }, 1000)
    }
  }
})
</script>
<style lang="scss">
.bottom-bar-right {
  display: flex;
  align-items: center;
  &__next-scribe-btn {
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 20px;
    min-width: auto;
  }
  &__scribe-options {
    margin-left: 5px;
    &--multiteam {
      .v-list__tile {
        width: 180px;
      }
    }
    .v-list__tile {
      width: 180px;
    }
  }
  &__note-btn {
    border-radius: 5px;
    padding: 4px 3px;
    background: transparent !important;
    &:hover {
      background: #798294 !important;
    }
    .v-icon {
      color: #fff;
    }
    &--active {
      background: #8680ff !important;
      &:hover {
        background: #36309e !important;
      }
    }
  }
}
</style>
